import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { client } from "./client";
import styled from "styled-components";

import DefaultLayout from "./common/DefaultLayout";
import CardWrapper from "./common/CardWrapper";
import Loading from "./Loading";
import ObjFilePreview from "./ObjFilePreview";
import PostPreview from "./PostPreview";
import TagDiv from "./common/TagDiv";
import TagIcon from "./common/TagIcon";

const TagsTitle = styled.h1`
  font-size: 1.25em;
  text-transform: uppercase;
  background-color: var(--decepticon-color);
  padding: 9px;
  color: #ffffff;
`;

const ResultsSubTitle = styled.h3`
  font-size: 1.2em;
  font-weight: bold;
  background-color: #555555;
  color: #ffffff;
  padding: 9px;
`;

const NoResults = styled.div`
  padding: 5px;
  color: #cccccc;
  font-style: italic;
`;

const Tag = () => {
  const [searchResults, setSearchResults] = useState<any>([]);
  const [tags, setTags] = useState<any>([]);

  async function fetchTags() {
    const result = await client
      .fetch(
        `*[defined(tags)]{
          tags
        }`
      )
      .catch((error: any) => console.error(error));
    if (result) combineTags(result);
  }

  const combineTags = (src: Array<any>) => {
    let newTags: Array<any> = [];
    src.forEach((itm: any) => {
      if (itm.tags) {
        itm.tags.forEach((tag: any) => {
          if (newTags.filter((t: any) => t.value === tag.value).length === 0) {
            newTags.push(tag);
          }
        });
      }
    });
    setTags(newTags);
  };

  async function fetchSearchResults() {
    const result = await client
      .fetch(
        `*['${id}' in tags[].label && !(_id in path('drafts.**'))]{
          _id,
          _type,
          title,
          tags,
          body,
          objType,
          author,
          colourScheme,
          "mainImage": main_image.asset->url,
          videos[]->{title,video_id},
          "objFiles": obj_files[]->{title, _id},
          publishedAt,
          excerpt,
          "thumbnail":thumbnail.asset->url,
        }`
      )
      .catch((error: any) => console.error(error));
    if (result) setSearchResults(result);
  }
  let { id } = useParams();

  useEffect(() => {
    fetchTags();
    fetchSearchResults();
  }, [id]);

  return (
    <DefaultLayout>
      {searchResults._id === null && <Loading />}
      {searchResults._id !== null && (
        <React.Fragment>
          <TagsTitle>
            Search results for <em>{id}</em>
          </TagsTitle>

          <div>
            <TagIcon />
            {tags.map((tag: any) => {
              return (
                <TagDiv
                  key={`tag-${encodeURIComponent(tag.value)}`}
                  link={encodeURIComponent(tag.value)}
                  label={tag.label}
                  selected={tag.value === id}
                />
              );
            })}
          </div>

          <div>
            <ResultsSubTitle>Posts</ResultsSubTitle>
            {searchResults.filter((tag: any) => tag._type === "post").length ===
              0 && <NoResults>No posts found.</NoResults>}
            <CardWrapper>
              {searchResults
                .filter((tag: any) => tag._type === "post")
                .map((tag: any) => (
                  <PostPreview key={`post-${tag._id}`} post={tag} />
                ))}
            </CardWrapper>
          </div>
          <div>
            <ResultsSubTitle>Models</ResultsSubTitle>

            {searchResults.filter(
              (tag: any) => tag._type === "objFile" || tag._type === "obj_file"
            ).length === 0 && <NoResults>No models found.</NoResults>}
            <CardWrapper>
              {searchResults
                .filter(
                  (tag: any) =>
                    tag._type === "objFile" || tag._type === "obj_file"
                )
                .map((tag: any) => (
                  <ObjFilePreview key={`objfile-${tag._id}`} src={tag} />
                ))}
            </CardWrapper>
          </div>
        </React.Fragment>
      )}
    </DefaultLayout>
  );
};

export default Tag;
