import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: grid;
  margin: 0 10px;
  gap: 20px;
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }

  @media (min-width: 481px) and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 769px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const CardWrapper = (props: any) => {
  return <Wrapper>{props.children}</Wrapper>;
};

export default CardWrapper;
