import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { client } from "./client";
import { useParams } from "react-router-dom";
import DefaultLayout from "./common/DefaultLayout";
import PostContent from "./PostContent";
import Loading from "./Loading";

const Post = () => {
  const [post, setPost] = useState<any>({ _id: null });
  let { id } = useParams();
  async function fetchPost() {
    const result = await client
      .fetch(
        `*[_type == 'post' && !(_id in path('drafts.**')) && _id == '${id}']{
          _id,
          title,
          tags,
          body,
          author,
          "mainImage": main_image.asset->url,
          videos[]->{title,video_id},
          "objFiles": obj_files[]->{_id, slug,
            title,
            objType,
            "thumbnail":thumbnail.asset->url,
            excerpt,
            colourScheme,
            "fileUrl":obj_file.asset->url},
          publishedAt,
          "thumbnail":thumbnail.asset->url,
          _updatedAt
        }`
      )
      .catch((error: any) => console.error(error));
    if (result) setPost(result[0]);
  }

  useEffect(() => {
    if (post._id === null) fetchPost();
  }, []);

  const serializers = {
    types: {
      code: (props: any) => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
    },
  };

  return (
    <DefaultLayout>
      {post._id === null && <Loading />}
      {post._id !== null && (
        <React.Fragment>
          <PostContent post={post} onLanding={false} />
        </React.Fragment>
      )}
    </DefaultLayout>
  );
};

export default Post;
