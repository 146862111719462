import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { client } from "./client";
import styled from "styled-components";

import DefaultLayout from "./common/DefaultLayout";
import Loading from "./Loading";
import TagDiv from "./common/TagDiv";
import TagIcon from "./common/TagIcon";

const TagsTitle = styled.h1`
  font-size: 1.25em;
  text-transform: uppercase;
  background-color: var(--decepticon-color);
  padding: 9px;
  color: #ffffff;
`;

const TagsWrapper = styled.div``;

const Tags = () => {
  const [tags, setTags] = useState<any>([]);

  async function fetchTags() {
    const result = await client
      .fetch(
        `*[defined(tags)]{
          tags
        }`
      )
      .catch((error: any) => console.error(error));
    if (result) combineTags(result);
  }

  const combineTags = (src: Array<any>) => {
    let newTags: Array<any> = [];
    src.forEach((itm: any) => {
      if (itm.tags) {
        itm.tags.forEach((tag: any) => {
          if (newTags.filter((t: any) => t.value === tag.value).length === 0) {
            newTags.push(tag);
          }
        });
      }
    });
    setTags(newTags);
  };

  useEffect(() => {
    if (tags.length === 0) fetchTags();
  }, []);

  return (
    <DefaultLayout>
      {tags.length === 0 && <Loading />}
      {tags.length > 0 && (
        <React.Fragment>
          <TagsTitle>Search</TagsTitle>
          <div>
            <TagsWrapper>
              <TagIcon />
              {tags.map((tag: any) => {
                return (
                  <TagDiv
                    key={`tag-${encodeURIComponent(tag.value)}`}
                    link={encodeURIComponent(tag.value)}
                    label={tag.label}
                  />
                );
              })}
            </TagsWrapper>
          </div>
        </React.Fragment>
      )}
    </DefaultLayout>
  );
};

export default Tags;
