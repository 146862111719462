import SanityClient from "@sanity/client";
export const projectId = "bbkci3qs";
export const dataset = "production";

export const client = SanityClient({
  projectId,
  dataset,
  useCdn: true,
  token:
    "skVFOCaVjvI8Wk3WFF04ZbxGRfqJa0uoq6fX7p2irLAsTZSldC96o3V9HS8QQGtPOS24jjWKqoTZuEjEOpwO7S6EETezoaFtE355xfAm9bgjkAacvQKyg6NQ9a1A8zfCZzKG2weQWThKBcdPvoWGo8B5ztTaCuw3QedOEOpkERzx15DYS80O",
});
