// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  --decepticon-color: #2c2272;
  --autobot-color: #be0b24;
  --other-color: #666666;
  --white-color: #ffffff;
}

.rsis-container {
  width: 100% !important;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,wBAAwB;EACxB,sBAAsB;EACtB,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":["body {\n  --decepticon-color: #2c2272;\n  --autobot-color: #be0b24;\n  --other-color: #666666;\n  --white-color: #ffffff;\n}\n\n.rsis-container {\n  width: 100% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
