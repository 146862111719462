import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import SocialLinks from "./SocialLinks";

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  color: #ffffff;
  background-color: #444444;
  padding: 10px 5px 0px 5px;
  box-sizing: border-box;

  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const SectionLinks = styled.div`
  font-size: 1.2em;
  a {
    color: #f1f1f1;
    text-transform: uppercase;
    text-decoration: none;
    padding: 5px 5px;
    font-weight: bold;
  }
  a:hover {
    color: #aaaaaa;
  }
  margin-bottom: 10px;

  @media (max-width: 660px) {
    font-size: 0.875em;
  }
`;

const SocialMediaLinks = styled.div`
  text-align: right;
  font-size: 1.2em;

  @media (max-width: 480px) {
    margin-bottom: 5px;
  }
`;

const StyledLink = styled(Link)<{ active: boolean }>`
  ${(props) => props.active === true && "color: #aaaaaa !important;"}
`;

const SiteMenu = (props: any) => {
  const { location } = props;

  return (
    <Wrapper>
      <SectionLinks>
        <StyledLink to={`/`} active={false}>
          <FontAwesomeIcon icon={faHouse} />
        </StyledLink>
        <StyledLink
          to={`/posts`}
          active={window.location.pathname.indexOf("/posts") > -1}
        >
          Posts
        </StyledLink>
        <StyledLink
          to={`/models`}
          active={window.location.pathname.indexOf("/models") > -1}
        >
          Models
        </StyledLink>
        <StyledLink
          to={`/tags`}
          active={window.location.pathname.indexOf("/tags") > -1}
        >
          Search
        </StyledLink>
      </SectionLinks>
      <SocialMediaLinks>
        <SocialLinks />
      </SocialMediaLinks>
    </Wrapper>
  );
};

export default SiteMenu;
