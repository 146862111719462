import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { client } from "./client";
import GenericSubTitle from "./common/GenericSubTitle";
import ObjFilePreview from "./ObjFilePreview";

const Wrapper = styled.div`
  color: #ffffff;

  a {
    background-color: var(--decepticon-color);
    color: #ffffff;
    font-weight: bold;
    text-decoration: none;
  }
`;

const ObjFilesWrapper = styled.div`
  display: grid;
  gap: 20px;
`;

const ViewAllWrapper = styled.div`
  margin-top: 20px;
  text-align: right;
`;

const StyledLink = styled(Link)`
  padding: 9px;
`;

const ObjFilesLanding = () => {
  const [objFiles, setObjFiles] = useState([]);

  async function fetchObjFiles() {
    const result = await client
      .fetch(
        `*[_type == 'obj_file' && !(_id in path('drafts.**'))] | order(_updatedAt desc)[0..3]
        {
        _id,
        slug,
        objType,
        title,
        colourScheme,
        progress,
        "thumbnail":thumbnail.asset->url,
        excerpt,
        "fileUrl":obj_file.asset->url
      }`
      )
      .catch((error: any) => console.error(error));
    if (result) setObjFiles(result);
  }

  useEffect(() => {
    if (objFiles.length === 0) fetchObjFiles();
  }, []);

  return (
    <Wrapper>
      <GenericSubTitle align={"right"}>Latest Models</GenericSubTitle>
      <ObjFilesWrapper>
        {objFiles.map((objFile: any) => (
          <ObjFilePreview key={`objfile-${objFile._id}`} src={objFile} />
        ))}
      </ObjFilesWrapper>
      <ViewAllWrapper>
        <StyledLink to={`/models`}>View All</StyledLink>
      </ViewAllWrapper>
    </Wrapper>
  );
};

export default ObjFilesLanding;
