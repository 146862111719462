import React from "react";

import styled from "styled-components";
import Footer from "./Footer";
import Header from "./Header";
import SiteMenu from "./SiteMenu";

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto 20px auto;
`;

const Wrapper = styled.div`
  background-color: #333333;
  min-height: 100vh;
  display: grid;
  grid-template-rows: 213.5px 1fr 60px;
`;

const Content = styled.div`
  box-sizing: border-box;
  padding: 10px;
  min-height: 250px;
`;

const DefaultLayout = (props: any) => {
  return (
    <Wrapper>
      <Header />
      <ContentWrapper>
        <SiteMenu />
        <Content>{props.children}</Content>
      </ContentWrapper>
      <Footer />
    </Wrapper>
  );
};

export default DefaultLayout;
