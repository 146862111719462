import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.span`
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-gap: 0px;
  cursor: pointer;
  :hover {
    .link_title {
      background-color: #cccccc;
    }
  }
  margin-bottom: 20px;
`;
const LinkTitle = styled.span`
  font-weight: bold;
  color: #ffffff;
  padding: 5px;
  font-size: 1.25em;
`;
const LinkIcon = styled.span`
  font-size: 1.25em;
`;

const DownloadLink = ({ linkObject }: { linkObject: any }) => {
  return (
    <Wrapper onClick={() => window.open(linkObject.site_url, "linkWindow")}>
      <LinkIcon>
        <FontAwesomeIcon icon={faLink} />
      </LinkIcon>
      <div>
        <LinkTitle className="link_title">{linkObject.site_name}</LinkTitle>
      </div>
    </Wrapper>
  );
};

export default DownloadLink;
