import React from "react";
import styled from "styled-components";
const Wrapper = styled.div`
  font-style: italic;
  font-size: 1em;
  color: #cccccc;
`;
const Loading = () => {
  return <Wrapper>Loading...</Wrapper>;
};

export default Loading;
