import React from "react";
import styled from "styled-components";

const Wrapper = styled.h3<{ align: string }>`
  font-size: 1.25em;
  text-transform: uppercase;
  background-color: var(--other-color);
  padding: 9px;
  text-align: ${(props) => props.align};

  @media (max-width: 480px) {
    text-align: left;
    margin-bottom: 5px;
  }
`;

const GenericSubTitle = ({
  children,
  align,
}: {
  children: any;
  align: string;
}) => {
  return <Wrapper align={align}>{children}</Wrapper>;
};

export default GenericSubTitle;
