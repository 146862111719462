import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import ObjFile from "./ObjFile";
import ObjFiles from "./ObjFiles";
import MainView from "./MainView";
import Tags from "./Tags";
import Tag from "./Tag";
import Posts from "./Posts";
import Post from "./Post";
import "./App.css";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainView />} />
        <Route path="/posts" element={<Posts />} />
        <Route path="/posts/:id" element={<Post />} />
        <Route path="/models" element={<ObjFiles />} />
        <Route path="/models/:id" element={<ObjFile />} />
        <Route path="/tags" element={<Tags />} />
        <Route path="/tags/:id" element={<Tag />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
