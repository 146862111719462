import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { client } from "./../client";
import SocialLink from "./SocialLink";

const Wrapper = styled.div`
  @media (max-width: 660px) {
    margin-top: -2px;
  }

  @media (max-width: 480px) {
    border-top: 1px solid #ffffff;
    padding-top: 10px;
  }
`;
const SocialLinks = () => {
  const [socialLinks, setSocialLinks] = useState([]);

  async function fetchSocialLinks() {
    const result = await client
      .fetch(
        `*[_type == 'social']{
        _id,
        link,
        title,
        "imageUrl":image.asset->url
      }`
      )
      .catch((error: any) => console.error(error));
    if (result) setSocialLinks(result);
  }

  useEffect(() => {
    if (socialLinks.length === 0) fetchSocialLinks();
  }, [fetchSocialLinks]);

  return (
    <Wrapper>
      {socialLinks.map((link: any) => (
        <SocialLink key={`link-${link._id}`} link={link} />
      ))}
    </Wrapper>
  );
};

export default SocialLinks;
