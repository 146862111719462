import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Suspense } from "react";
import { client } from "./client";
import { Link, useParams } from "react-router-dom";
import DefaultLayout from "./common/DefaultLayout";
import Loading from "./Loading";
import TagDiv from "./common/TagDiv";
import TagIcon from "./common/TagIcon";
import LastUpdated from "./common/LastUpdated";
import GenericSubTitle from "./common/GenericSubTitle";
import DownloadLink from "./common/DownloadLink";
import useWindowSize from "./hooks/useWindowSize";
import BuildProgress from "./common/BuildProgress";
import Slideshow from "./common/Slideshow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChildReaching,
  faCube,
  faCubes,
  faGun,
  faHandSpock,
  faJedi,
  faMicroscope,
  faOtter,
  faPenRuler,
  faSackDollar,
  faSkull,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
const BlockContent = require("@sanity/block-content-to-react");

const Wrapper = styled.div`
  width: 100%;
  color: #ffffff;
`;
const ObjFileTitle = styled.h1<{ colourScheme: string }>`
  font-size: 1.25em;
  text-transform: uppercase;
  padding: 9px;
  color: #ffffff;
  background-color: var(
    --${(props) => (props.colourScheme ? props.colourScheme : "other")}-color
  );
`;

const DownloadLinks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const MetaInfo = styled.div`
  display: grid;
  font-size: 0.875em;
  text-transform: uppercase;
  margin-bottom: 10px;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  @media (max-width: 468px) {
    grid-template-columns: 1fr;
  }
`;

const MetaItem = styled.div`
  color: #ffffff;
  font-weight: bold;
  span:first-of-type {
    font-style: italic;
    margin-right: 10px;
  }
`;

const MetaExcerpt = styled.div<{ colourScheme: string }>`
  padding: 9px;
  color: #ffffff;
  font-size: 0.875em;
  border-left: 10px solid
    var(
      --${(props) => (props.colourScheme ? props.colourScheme : "other")}-color
    );
`;

const MetaIcon = styled.span`
  font-size: 1.25em;
`;

const CanvasWrapper = styled.div<{ width: number }>`
  height: 400px;
  width: ${(props) => props.width}px;
  min-width: 0px;
  min-height: 0px;
  background-color: #333333;
`;

const ObjSelectors = styled.div`
  margin-bottom: 10px;
`;

const ObjPreview = styled.div`
  iframe {
    width: 100%;
    max-height: 600px;
  }
`;

const ObjSelector = styled.span<{ colourScheme: any; active: boolean }>`
  font-size: 1em;
  font-weight: bold;
  padding: 10px;
  margin-right: 10px;
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
  background-color: var(
    --${(props) => {
        if (props.active === true) {
          return "white";
        } else {
          return props.colourScheme ? props.colourScheme : "other";
        }
      }}-color
  );
  color: var(
    --${(props) => {
        if (props.active !== true) {
          return "white";
        } else {
          return props.colourScheme ? props.colourScheme : "other";
        }
      }}-color
  );

  :hover {
    background-color: var(--white-color);
    color: var(
      --${(props) => {
          return props.colourScheme ? props.colourScheme : "other";
        }}-color
    );
  }
`;

const ObjFile = () => {
  const [objFile, setObjFile] = useState<any>({ _id: null });
  const [selectedObjFile, setSelectedObjFile] = useState<any>(null);

  // let params = useParams();
  let { id } = useParams();
  async function fetchObjFile() {
    const result = await client
      .fetch(
        `*[_type == 'obj_file' && !(_id in path('drafts.**')) && _id == '${id}'][0]{
            _id,
            colourScheme,
            title,
            objType,
            tags,
            description,
            progress,
            price,
            excerpt,
            embedCode,
            "slideshow": slideshow[].asset->url,
            "fileUrls":obj_files[].obj_file.asset->url,
            "bannerUrl":banner_image.asset->url,
            "downloadLinks": download_links,
            _updatedAt,
            "buildProgress": build_progress[]->{title, _id, type, "thumbnail": thumbnail.asset->url, _type, "videoID": video_id}
      }`
      )
      .catch((error: any) => console.error(error));
    if (result) setObjFile(result);
    if (result.fileUrls && result.fileUrls.length > 0) setSelectedObjFile(0);
  }

  useEffect(() => {
    if (objFile._id === null) fetchObjFile();
  }, []);

  const serializers = {
    types: {
      code: (props: any) => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
    },
  };
  const size = useWindowSize();

  const getIconType = () => {
    switch (objFile.objType) {
      case "head":
        return faSkull;
      case "bust":
        return faUser;
      case "figure":
        return faChildReaching;
      case "weapon":
        return faGun;
      case "misc":
        return faCube;
      case "starwars":
        return faJedi;
      case "startrek":
        return faHandSpock;
      default:
        return faCube;
    }
  };

  const getProgressIcon = () => {
    switch (objFile.progress) {
      case "designing":
        return faPenRuler;
      case "modelling":
        return faUser;
      case "testing":
        return faMicroscope;
      case "completed":
        return faCheckCircle;
      case "other":
        return faOtter;
      default:
        return faOtter;
    }
  };

  return (
    <DefaultLayout>
      <Wrapper>
        {objFile._id === null && <Loading />}
        {objFile._id !== null && (
          <React.Fragment>
            <ObjFileTitle colourScheme={objFile.colourScheme}>
              {objFile.title}
            </ObjFileTitle>
            <div>
              <TagIcon />
              {objFile.tags &&
                objFile.tags.map((tag: any) => {
                  return (
                    <TagDiv
                      key={`tag-${encodeURIComponent(tag.value)}`}
                      link={encodeURIComponent(tag.value)}
                      label={tag.label}
                    />
                  );
                })}
            </div>
            <MetaInfo>
              <MetaItem>
                <span>Type:</span>
                <span>
                  {objFile.objType || "N/A"} &nbsp;
                  <MetaIcon>
                    <FontAwesomeIcon icon={getIconType()} />
                  </MetaIcon>
                </span>
              </MetaItem>
              <MetaItem>
                <span>Progress:</span>
                <span>
                  {objFile.progress || "N/A"}&nbsp;
                  <MetaIcon>
                    <FontAwesomeIcon icon={getProgressIcon()} />
                  </MetaIcon>
                </span>
              </MetaItem>
              <MetaItem>
                <span>Price:</span>
                <span>
                  <FontAwesomeIcon icon={faSackDollar} />
                  &nbsp;
                  {objFile.price !== null
                    ? objFile.price === 0
                      ? "FREE"
                      : "$" + objFile.price
                    : "N/A"}
                  &nbsp;
                </span>
              </MetaItem>
            </MetaInfo>
            {objFile.excerpt && (
              <MetaExcerpt colourScheme={objFile.colourScheme}>
                {objFile.excerpt}
              </MetaExcerpt>
            )}
            <div>
              {objFile.slideshow && <Slideshow src={objFile.slideshow} />}
            </div>

            <div>
              <BlockContent
                blocks={objFile.description}
                serializers={serializers}
              />
            </div>
            <div>
              <GenericSubTitle align="left">Preview</GenericSubTitle>
              {objFile.embedCode && (
                <ObjPreview
                  dangerouslySetInnerHTML={{ __html: objFile.embedCode }}
                />
              )}
            </div>
            <div>
              <GenericSubTitle align={"left"}>
                Where to Download
              </GenericSubTitle>
              <DownloadLinks>
                {objFile.downloadLinks &&
                  objFile.downloadLinks.map((download_link: any) => {
                    return (
                      <DownloadLink
                        key={`download-link.${download_link._key}`}
                        linkObject={download_link}
                      />
                    );
                  })}
              </DownloadLinks>
            </div>
            <div>
              {objFile.buildProgress && objFile.buildProgress.length > 0 && (
                <React.Fragment>
                  <GenericSubTitle align="left">Build Progress</GenericSubTitle>
                  <BuildProgress src={objFile.buildProgress} />
                </React.Fragment>
              )}
              <LastUpdated updatedAt={objFile._updatedAt} />
            </div>
          </React.Fragment>
        )}
      </Wrapper>
    </DefaultLayout>
  );
};

export default ObjFile;
