import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { client } from "../client";

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  color: #ffffff;
`;

const SiteLogo = styled.div`
  margin: 0px auto 0px auto;
  width: 150px;
  padding: 30px 0px 30px;
  box-sizing: border-box;
  object-fit: contain;

  img {
    max-width: 100%;
  }
`;

const Header = styled.div`
  background-image: url(/images/header.png);
  background-size: cover;
  background-position: center;
`;

const SiteInfo = () => {
  const [siteInfo, setSiteInfo] = useState({
    title: "",
    description: "",
    imageUrl: "",
  });

  async function fetchSiteInfo() {
    const result = await client
      .fetch(
        `*[_type == 'config']{
        title,
        description,
        "imageUrl":image.asset->url
      }[0]`
      )
      .catch((error: any) => console.error(error));
    if (result) setSiteInfo(result);
  }

  useEffect(() => {
    if (siteInfo.title === "") {
      fetchSiteInfo();
    }
  }, [siteInfo]);

  return (
    <Wrapper>
      <Header id="site-header">
        <SiteLogo>
          <Link to={`/`}>
            <img src="/images/site_logo.png" alt={siteInfo.title} />
          </Link>
        </SiteLogo>
      </Header>
    </Wrapper>
  );
};

export default SiteInfo;
