export const getUpdatedDate = (updatedAt) => {
  var today = new Date(updatedAt);

  return today.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    weekday: "long",
    year: "numeric",
  });
};
