import React from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

const ThumbnailImage = styled.img`
  width: 75px;
  height: 75px;
  object-fit: cover;
`;

const Preview = styled.div<{ src: string | undefined }>`
  display: grid;
  grid-template-columns: 75px 1fr;
  grid-gap: 5px;
  margin-bottom: 10px;
  position: relative;

  height: 150px;
  cursor: pointer;

  background-color: #cccccc;
  background-size: cover;
  background-position: center;

  background-image: url(${(props) => props.src});

  :hover {
    .preview_title {
      color: var(--decepticon-color);
      background-color: #ffffff;
    }
  }
`;

const PreviewTitle = styled.div<{ hasExcerpt: boolean }>`
  background-color: var(--decepticon-color);
  color: #ffffff;
  font-weight: bold;
  padding: 2px 4px;
  box-sizing: border-box;
  ${(props) => props.hasExcerpt !== true && "margin-bottom: 5px;"}
`;

const PreviewExcerpt = styled.div`
  font-size: 0.87em;
  color: #ffffff;
  padding: 5px;

  background-color: #00000050;
`;

const PreviewWrapper = styled.div`
  position: absolute;
  left: 5px;
  bottom: 0px;
`;

const PostPreview = (props: any) => {
  const { post } = props;
  const navigate = useNavigate();

  return (
    <Preview
      key={`post-${post._id}`}
      src={post.thumbnail}
      onClick={() => {
        navigate(`/posts/${post._id}`);
      }}
    >
      <PreviewWrapper>
        <PreviewTitle
          className="preview_title"
          hasExcerpt={post.excerpt > "" && post.excerpt !== null}
        >
          {post.title}
        </PreviewTitle>
        {post.excerpt && <PreviewExcerpt>{post.excerpt}</PreviewExcerpt>}
      </PreviewWrapper>
    </Preview>
  );
};
export default PostPreview;
