import React from "react";
import styled from "styled-components";

const Wrapper = styled.a`
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  margin-right: 10px;

  :hover {
    img {
      background-color: #cccccc;
    }
  }
`;
const Icon = styled.img`
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border-radius: 30px;

  overflow: hidden;
  padding: 2px;

  @media (max-width: 660px) {
    width: 15px;
    height: 15px;
    padding: 1px;
  }
`;
const SocialLink = (obj: any) => {
  const { link } = obj;
  return (
    <Wrapper
      href={link.link}
      target="_blank"
      title={`Follow G1Ish on ${link.title}`}
    >
      <Icon alt={`Follow G1Ish on ${link.title}`} src={link.imageUrl} />
    </Wrapper>
  );
};

export default SocialLink;
