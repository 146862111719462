import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useWindowSize from "../hooks/useWindowSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 150px));
  gap: 10px;
  margin-top: 10px;
`;

const SlideImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  cursor: pointer;
`;

const SlideModal = styled.div`
  position: fixed;
  left: 10px;
  top: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  padding: 20px;
  background-color: #ffffff;
  box-sizing: border-box;
  cursor: pointer;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 2em;
  color: #000000;
`;

const ModalImage = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

const Slideshow = ({ src }: { src: Array<any> }) => {
  const size = useWindowSize();
  const [modalItem, setModalItem] = useState<string | false>(false);

  const keyUpHandler = (ev: any) => {
    if (modalItem !== false && ev.key === "Escape") {
      setModalItem(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keyup", keyUpHandler);
    return () => {
      window.removeEventListener("keyup", keyUpHandler);
    };
  }, []);

  return (
    <Wrapper>
      {src.map((img: any) => {
        return (
          <SlideImage
            key={`slide-image-${img}`}
            src={img}
            alt={img}
            onClick={() => setModalItem(img)}
          />
        );
      })}
      {modalItem !== false && (
        <SlideModal onClick={() => setModalItem(false)}>
          <CloseButton>
            <FontAwesomeIcon icon={faClose} />
          </CloseButton>
          <ModalImage src={modalItem} alt={modalItem} />
        </SlideModal>
      )}
    </Wrapper>
  );
};

export default Slideshow;
