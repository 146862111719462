import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.span<{ selected: boolean }>`
  background-color: #666666;
  color: #ffffff;
  font-size: 0.875em;
  padding: 4px 8px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  margin: 0px 10px 10px 0px;
  display: inline-block;

  :hover {
    background-color: #ffffff;
    color: #333333;
  }

  :last-of-type {
    margin-right: 0px;
  }

  ${(props) =>
    props.selected === true && `background-color: #ffffff; color: #666666;`}
`;

const TagDiv = ({
  link,
  label,
  selected,
}: {
  link: string;
  label: string;
  selected?: boolean;
}) => {
  let navigate = useNavigate();

  return (
    <Wrapper
      onClick={() => navigate(`/tags/${link}`)}
      selected={selected === true}
    >
      {label}
    </Wrapper>
  );
};

export default TagDiv;
