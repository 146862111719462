import React from "react";
import styled from "styled-components";
import { getUpdatedDate } from "../utils/getUpdatedDate";

const Wrapper = styled.div`
  font-size: 0.75em;
  padding: 10px;
  margin-top: 10px;
  color: #ffffff;
`;

const LastUpdated = ({ updatedAt }: { updatedAt: string }) => {
  return (
    <Wrapper>
      This post was last updated on <strong>{getUpdatedDate(updatedAt)}</strong>
      .
    </Wrapper>
  );
};

export default LastUpdated;
