import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { client } from "./client";
import useWindowSize from "./hooks/useWindowSize";
import PostContent from "./PostContent";
import PostPreview from "./PostPreview";

const Wrapper = styled.div`
  color: #ffffff;
`;

const PostsTitle = styled.h3`
  font-size: 1.25em;
  text-transform: uppercase;
  background-color: var(--decepticon-color);
  padding: 9px;

  @media (max-width: 480px) {
    margin-bottom: 5px;
  }
`;

const Posts = () => {
  const [posts, setPosts] = useState([]);
  const [showPost, setShowPost] = useState<boolean>(false);

  async function fetchPosts() {
    const result = await client
      .fetch(
        `*[_type == 'post' && !(_id in path('drafts.**')) && pinned == true] | order(_updatedAt desc)[0..3] {
          _id,
          title,
          tags,
          body,
          author,
          "mainImage": main_image.asset->url,
          videos[]->{title,video_id},
          "objFiles": obj_files[]->{title, _id},
          publishedAt,
          "thumbnail":thumbnail.asset->url,
      }`
      )
      .catch((error: any) => console.error(error));
    if (result) setPosts(result);
  }

  useEffect(() => {
    if (posts.length === 0) fetchPosts();
  }, []);

  const size = useWindowSize();

  return (
    <Wrapper>
      {!size.width ||
        (size.width &&
          size?.width > 600 &&
          posts.slice(0, 1).map((post: any) => {
            return (
              <PostContent
                key={`post-${post._id}`}
                post={post}
                onLanding={true}
              />
            );
          }))}
      {size.width && size.width < 600 && (
        <React.Fragment>
          <PostsTitle>Latest Posts</PostsTitle>

          {posts.slice(0, 1).map((post: any) => {
            return (
              <PostPreview
                key={`post-${post._id}`}
                post={post}
                onLanding={true}
              />
            );
          })}
        </React.Fragment>
      )}
    </Wrapper>
  );
};

export default Posts;
