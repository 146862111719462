import React from "react";
import styled from "styled-components";
import DefaultLayout from "./common/DefaultLayout";
import ObjFilesLanding from "./ObjFilesLanding";
import PostsLanding from "./PostsLanding";

const LandingGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const Blurb = styled.div`
  font-size: 1em;
  color: #ffffff;
  border-bottom: 1px solid #ffffff30;
  padding: 20px;
`;

const MainView = () => {
  return (
    <DefaultLayout>
      <Blurb>
        <em>Howdy!</em> This is a showcase and repository for my very
        slow-moving project to make a cartoon-style stop-motion video/series
        feature G1ish Transforming Robots!
        <p>
          It's also where I'm putting up the various other nerd-themed things
          I'm modelling.
        </p>
      </Blurb>
      <LandingGrid>
        <PostsLanding />
        <ObjFilesLanding />
      </LandingGrid>
    </DefaultLayout>
  );
};

export default MainView;
