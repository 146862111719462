import {
  faCheckCircle,
  faHandSpock,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import {
  faChildReaching,
  faCube,
  faGun,
  faJedi,
  faMicroscope,
  faOtter,
  faPenRuler,
  faSkull,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  cursor: pointer;
`;

const Preview = styled.div<{
  src: string | undefined;
  colourScheme: string | undefined;
}>`
  display: grid;
  grid-template-columns: 75px 1fr;
  grid-gap: 5px;
  position: relative;

  height: 150px;

  background-color: #cccccc;
  background-size: cover;
  background-position: center;

  background-image: url(${(props) => props.src});

  :hover {
    .preview_title {
      color: var(
        --${(props) => (props.colourScheme ? props.colourScheme : "other")}-color
      );
      background-color: #ffffff;
    }
  }
`;

const PreviewTitle = styled.div<{ colourScheme: string | undefined }>`
  position: absolute;
  left: 5px;
  bottom: 5px;
  background-color: var(
    --${(props) => (props.colourScheme ? props.colourScheme : "other")}-color
  );
  color: #ffffff;
  font-weight: bold;
  padding: 2px 4px;
  box-sizing: border-box;
`;

const PreviewType = styled.span<{ colourScheme: string | undefined }>`
  // width: 72px;
  background-color: #ffffff;
  height: 31px;
  color: var(
    --${(props) => (props.colourScheme ? props.colourScheme : "other")}-color
  );
  position: absolute;
  right: 5px;
  top: 5px;
  box-sizing: border-box;
  padding-top: 2px;
  padding-right: 5px;
  padding-left: 4px;
  font-size: 1.4em;
  text-align: right;
`;

const ObjFilePreview = (props: any) => {
  let navigate = useNavigate();
  const getIconType = () => {
    switch (props.src.objType) {
      case "head":
        return faSkull;
      case "bust":
        return faUser;
      case "figure":
        return faChildReaching;
      case "weapon":
        return faGun;
      case "misc":
        return faCube;
      case "starwars":
        return faJedi;
      case "startrek":
        return faHandSpock;
      default:
        return faCube;
    }
  };

  const getProgressIcon = () => {
    switch (props.src.progress) {
      case "designing":
        return faPenRuler;
      case "modelling":
        return faUser;
      case "testing":
        return faMicroscope;
      case "completed":
        return faCheckCircle;
      case "other":
        return faOtter;
      default:
        return faOtter;
    }
  };

  const model = props.src;
  return (
    <Wrapper>
      <Preview
        key={`post-${model._id}`}
        src={model.thumbnail}
        colourScheme={model.colourScheme}
        onClick={() => {
          navigate(`/models/${props.src._id}`);
        }}
      >
        <PreviewType colourScheme={model.colourScheme}>
          <FontAwesomeIcon icon={getIconType()} />
          &nbsp;
          <FontAwesomeIcon icon={getProgressIcon()} />
        </PreviewType>
        <PreviewTitle
          colourScheme={model.colourScheme}
          className="preview_title"
        >
          {model.title}
        </PreviewTitle>
      </Preview>
    </Wrapper>
  );
};

export default ObjFilePreview;
