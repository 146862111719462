import React from "react";
import styled from "styled-components";
const Wrapper = styled.div`
  padding: 10px;
  background-color: #444444;
  color: #ffffff;
  text-align: center;

  font-size: 1em;

  @media (max-width: 600px) {
    font-size: 0.75em;
  }
`;
const SiteEmail = styled.div`
  a {
    text-decoration: none;
    font-weight: bold;
    color: #cccccc;
  }

  a:hover {
    color: #cccccc;
  }
`;

const Footer = () => {
  return (
    <Wrapper>
      <SiteEmail>
        This is a personal project by Brian Hogg. Send an email to{" "}
        <a href="mailto:brian@hoggworks.com">brian@hoggworks.com</a> with any
        questions/comments/feedback you may have.
      </SiteEmail>
    </Wrapper>
  );
};

export default Footer;
