import { faTags } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.span`
  font-size: 0.875em;
  color: #ffffff;
  padding-right: 10px;
`;

const TagIcon = () => {
  return (
    <Wrapper>
      <FontAwesomeIcon icon={faTags} />
    </Wrapper>
  );
};

export default TagIcon;
