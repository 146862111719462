import React from "react";
import styled from "styled-components";

import { projectId, dataset } from "./client";
import { useNavigate } from "react-router-dom";
import VideoEmbed from "./common/VideoEmbed";
import TagDiv from "./common/TagDiv";
import TagIcon from "./common/TagIcon";
import CardWrapper from "./common/CardWrapper";
import ObjFilePreview from "./ObjFilePreview";
import GenericSubTitle from "./common/GenericSubTitle";
import LastUpdated from "./common/LastUpdated";
const BlockContent = require("@sanity/block-content-to-react");

const Wrapper = styled.div`
  color: #ffffff;

  a {
    background-color: var(--decepticon-color);
    color: #ffffff;
    text-decoration: none;
    padding: 2px 4px;
  }
`;

const PostTitle = styled.h1`
  font-size: 1.25em;
  text-transform: uppercase;
  padding: 9px 9px 9px 0px;
`;

const PostTitleLanding = styled.h3`
  font-size: 1.25em;
  text-transform: uppercase;
  padding: 9px 9px 9px 0px;
  cursor: pointer;
`;

const serializers = {
  types: {
    code: (props: any) => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
};

const ReadMoreWrapper = styled.span`
  text-transform: uppercase;
  text-align: center;
  font-size: 1.25em;
  color: #ffffff;
  padding: 9px;
  font-weight: bold;
  background-color: var(--decepticon-color);
  cursor: pointer;
  :hover {
    color: var(--decepticon-color);
    background-color: #ffffff;
  }
`;

const CentreText = styled.div`
  margin-top: 40px;
  text-align: center;
`;

const ReadMore = ({ href }: { href: string }) => {
  const navigate = useNavigate();
  return (
    <CentreText>
      <ReadMoreWrapper onClick={() => navigate(href)}>
        Read More
      </ReadMoreWrapper>
    </CentreText>
  );
};

const PostContent = ({
  post,
  onLanding,
}: {
  post: any;
  onLanding: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      {onLanding === false && <PostTitle>{post.title}</PostTitle>}
      {onLanding === true && (
        <PostTitleLanding onClick={() => navigate(`/posts/${post._id}`)}>
          {post.title}
        </PostTitleLanding>
      )}

      {post.tags && post.tags.length > 0 && (
        <div>
          <TagIcon />
          {post.tags &&
            post.tags.map((tag: any) => {
              return (
                <TagDiv
                  key={`tag-${encodeURIComponent(tag.value)}`}
                  link={encodeURIComponent(tag.value)}
                  label={tag.label}
                />
              );
            })}
        </div>
      )}
      {post.videos && post.videos.length > 0 && (
        <div>
          <VideoEmbed
            embedId={post.videos[0].video_id}
            title={post.videos[0].title}
          />
        </div>
      )}
      <div>
        <div>
          <BlockContent
            blocks={onLanding === true ? post.body.slice(0, 3) : post.body}
            projectId={projectId}
            dataset={dataset}
            serializers={serializers}
            imageOptions={{ w: 320, h: 240, fit: "max" }}
          />
        </div>
        {onLanding === true && <ReadMore href={`/posts/${post._id}`} />}
        {onLanding === false && (
          <React.Fragment>
            <div>
              {post.objFiles && (
                <div>
                  <GenericSubTitle align="left">Related Models</GenericSubTitle>
                  <CardWrapper>
                    {post.objFiles.map((objFile: any) => {
                      return (
                        <div key={`obj-file-link.${objFile._ref}`}>
                          <ObjFilePreview
                            key={`obj-file-link.${objFile._ref}`}
                            src={objFile}
                          />
                        </div>
                      );
                    })}
                  </CardWrapper>
                </div>
              )}
            </div>
            <LastUpdated updatedAt={post._updatedAt} />
          </React.Fragment>
        )}
      </div>
    </Wrapper>
  );
};

export default PostContent;
