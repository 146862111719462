import React from "react";
import styled from "styled-components";
import PostPreview from "../PostPreview";
import CardWrapper from "./CardWrapper";
import VideoEmbed from "./VideoEmbed";

const Wrapper = styled.div``;

const BuildProgress = ({ src }: { src: Array<any> }) => {
  return (
    <Wrapper>
      <CardWrapper>
        {src &&
          src.map((srcItem: any) => {
            switch (srcItem._type) {
              case "post":
                return (
                  <PostPreview key={`src-item-${srcItem._id}`} post={srcItem} />
                );
              case "video":
                return (
                  <VideoEmbed
                    key={`src-item-${srcItem._id}`}
                    embedId={srcItem.videoID}
                    title={srcItem.title}
                  />
                );
            }
          })}
      </CardWrapper>
    </Wrapper>
  );
};

export default BuildProgress;
