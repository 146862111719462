import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { client } from "./client";
import DefaultLayout from "./common/DefaultLayout";
import CardWrapper from "./common/CardWrapper";
import ObjFilePreview from "./ObjFilePreview";

const ObjFilesTitle = styled.h1`
  font-size: 1.25em;
  text-transform: uppercase;
  background-color: var(--other-color);
  padding: 9px;
  color: #ffffff;
`;

const ObjFiles = () => {
  const [objFiles, setObjFiles] = useState([]);

  async function fetchObjFiles() {
    const result = await client
      .fetch(
        `*[_type == 'obj_file' && !(_id in path('drafts.**'))] | order(_updatedAt desc)
        {
        _id,
        slug,
        title,
        objType,
        "thumbnail":thumbnail.asset->url,
        excerpt,
        colourScheme,
        progress,
        "fileUrl":obj_file.asset->url
      }`
      )
      .catch((error: any) => console.error(error));
    if (result) setObjFiles(result);
  }

  useEffect(() => {
    if (objFiles.length === 0) fetchObjFiles();
  }, []);

  return (
    <DefaultLayout>
      <ObjFilesTitle>All Models</ObjFilesTitle>
      <CardWrapper>
        {objFiles.map((objFile: any) => (
          <ObjFilePreview key={`objfile-${objFile._id}`} src={objFile} />
        ))}
      </CardWrapper>
    </DefaultLayout>
  );
};

export default ObjFiles;
