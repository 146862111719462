import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { client } from "./client";
import DefaultLayout from "./common/DefaultLayout";
import CardWrapper from "./common/CardWrapper";
import PostPreview from "./PostPreview";

const PostsTitle = styled.h1`
  font-size: 1.25em;
  text-transform: uppercase;
  background-color: var(--other-color);
  padding: 9px;
  color: #ffffff;
`;

const Posts = () => {
  const [posts, setPosts] = useState([]);

  async function fetchPosts() {
    const result = await client
      .fetch(
        `*[_type == 'post' && !(_id in path('drafts.**'))] | order(_updatedAt desc) {
        _id,
        slug,
        excerpt,
        title,
        "thumbnail":thumbnail.asset->url
      }`
      )
      .catch((error: any) => console.error(error));
    if (result) setPosts(result);
  }

  useEffect(() => {
    if (posts.length === 0) fetchPosts();
  }, []);

  return (
    <DefaultLayout>
      <PostsTitle>All Posts</PostsTitle>
      <CardWrapper>
        {posts.map((post: any) => (
          <PostPreview key={`post-${post._id}`} post={post} />
        ))}
      </CardWrapper>
    </DefaultLayout>
  );
};

export default Posts;
